@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
    padding: 0;
    margin: 0;
    font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

@layer base {
    h1, h2, h3, h4, h5 {
        font-family: 'Mackinac', serif;
    }
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/Inter-Regular.woff2') format('woff2'),
    url('/fonts/Inter-Regular.woff') format('woff');
}

@font-face {
    font-family: 'Mackinac';
    font-style: normal;
    font-weight: 700;
    src: url('/fonts/mackinac-bold.woff2') format('woff2');
}
